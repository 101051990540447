import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Opinion Group`}</h1>
    <p>{`Abstractly, an opinion group is any `}<a parentName="p" {...{
        "href": "/group",
        "title": "group"
      }}>{`group`}</a>{` of `}<a parentName="p" {...{
        "href": "/participants",
        "title": "participants"
      }}>{`participants`}</a>{` which can be identified as having similar opinions in relation to some or all of the topics in a `}<a parentName="p" {...{
        "href": "/conversation",
        "title": "conversation"
      }}>{`conversation`}</a>{`.`}</p>
    <p>{`There is no one "right way" to detect `}<a parentName="p" {...{
        "href": "/opinion-groups",
        "title": "opinion groups"
      }}>{`opinion groups`}</a>{` within a conversation.
Many algorithms exist for doing this, and are generally considered `}<a parentName="p" {...{
        "href": "/clustering-algorithms",
        "title": "clustering algorithms"
      }}>{`clustering algorithms`}</a>{`, and groups within the conversation identified with individual `}<a parentName="p" {...{
        "href": "/clusters",
        "title": "clusters"
      }}>{`clusters`}</a>{`.`}</p>
    <p>{`However, the `}<a parentName="p" {...{
        "href": "/opinion-group",
        "title": "opinion group"
      }}>{`opinion group`}</a>{` is an abstract concept, and need not be the results of an algorithm.
They could for instance be defined based on responses to a particular set of hand-selected comments.
But the term can also be used much more abstractly, for example, as an abstract reference to hypothetical latent "real world" opinion-types driving `}<a parentName="p" {...{
        "href": "/participants",
        "title": "participants"
      }}>{`participants`}</a>{` responses.`}</p>
    <p>{`For more see:`}</p>
    <ul>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/opinion-groups",
          "title": "opinion groups"
        }}>{`opinion groups`}</a>{` for additional context around how opinion groups are used in `}<a parentName="li" {...{
          "href": "/Polis",
          "title": "Polis"
        }}>{`Polis`}</a>{``}</li>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/clustering-algorithms",
          "title": "clustering algorithms"
        }}>{`clustering algorithms`}</a>{``}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      